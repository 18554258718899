import axios from 'axios';
import { useState, useEffect } from 'react';
import { Table, Spinner, Alert } from 'react-bootstrap';

function Createcustomcourse() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/customcourse');
        if (response.data && response.data.length > 0) {
          // Sort data by timestamp in descending order (latest to earliest)
          const sortedData = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          setData(sortedData); // Use sortedData instead of response.data
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData(); // Fetch data initially when the component mounts
  }, []); // Empty dependency array ensures useEffect runs only once
  
  if (loading) {
    return <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>;
  }

  if (error) {
    return <Alert variant="danger">Error fetching data: {error.message}</Alert>;
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Qualification</th>
          <th>Courses</th>
          <th>Others</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            <td>{item.fullName}</td>
            <td>{item.email}</td>
            <td>{item.mobile}</td>
            <td>{item.qualification}</td>
            <td>
              {item.pythonTopics && item.pythonTopics.length > 0 ? (
                item.pythonTopics.map((topic, index) => (
                  <div key={index}>{topic}</div>
                ))
              ) : null}
              {item.mlTopics && item.mlTopics.length > 0 ? (
                item.mlTopics.map((topic, index) => (
                  <div key={index}>{topic}</div>
                ))
              ) : null}
              {item.dlTopics && item.dlTopics.length > 0 ? (
                item.dlTopics.map((topic, index) => (
                  <div key={index}>{topic}</div>
                ))
              ) : null}
              {!item.pythonTopics?.length && !item.mlTopics?.length && !item.dlTopics?.length && (
                'No topics available'
              )}
            </td>
            <td>{item.otherCourses}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default Createcustomcourse;
