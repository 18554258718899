import React, { useContext, useState } from 'react';
import { Container, Stack, Row, Col, Button } from 'react-bootstrap';
import { UserContext } from '../context/userContext';
import { Link, useNavigate } from 'react-router-dom';
import './chatbot.css';
import axios from 'axios';

function Dashboard(props) {
    let navigate = useNavigate();
    let user = useContext(UserContext);
    const [showInstaQandA, setShowInstaQandA] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const handleSend = async () => {
        if (input.trim() === '') return;

        setMessages([...messages, { text: input, type: 'user' }]);
        setInput('');

        try {
            const response = await axios.post('http://127.0.0.1:5000/ask', { question: input });
            setMessages([...messages, { text: input, type: 'user' }, { text: response.data.answer, type: 'bot' }]);
        } catch (error) {
            setMessages([...messages, { text: input, type: 'user' }, { text: 'Error: ' + error.message, type: 'bot' }]);
        }
    };

    const handleInstaQandAClick = () => {
        setShowInstaQandA(true);
    };

    if (user == null) {
        return (<Link className="btn btn-primary" to="/login">Login to continue</Link>);
    } else {
        return (
            <>
                <Stack>
                    <Row>
                        <Col>
                            <Container className="w-100 mb-2">
                                <h2>Exercises</h2>
                                <p><center>Structured tasks for hands-on examples of learning concepts</center></p>
                                <Link to="/ex">
                                    <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                </Link>
                            </Container>
                        </Col>
                        <Col>
                            <Container className="w-100 mb-2">
                                <h2>FAQ's</h2>
                                <p><center>Commonly asked questions providing quick clarification on key concepts</center></p>
                                <Link to="/faq">
                                    <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                </Link>
                            </Container>
                        </Col>
                        <Col >
                            <Container className="w-100 mb-2">
                                <h2>Notes</h2>
                                <p><center>Concise summaries highlighting key points.Click on the notes in top to get started</center></p>
                                <Link to="/">
                                    <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                </Link>
                            </Container>
                        </Col>
                    </Row>
                </Stack>

                <div className="chatbot">
                    <div className="chat-window">
                        {messages.map((msg, index) => (
                            <div key={index} className={`message ${msg.type}`}>
                                {msg.text}
                            </div>
                        ))}
                    </div>
                    <div className="chat-input">
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="How can I help you..."
                        />
                        <button onClick={handleSend}>Send</button>
                    </div>
                </div>
            </>
        );
    }
}

export default Dashboard;
