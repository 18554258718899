import React, { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CookiesProvider, useCookies } from "react-cookie";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { tryToLogin } from "../util/loginutil";
import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
import Dashboard from "./Dashboard";
import AddNewexercise from "./Exercises/Addnewexercise";
import PracticeExercises from "./Exercises/PracticeExercises";
import SearchExercises, { SearchResultsExercises } from "./Exercises/Searchexercise";
import SelectExercises from "./Exercises/SelectExercises";
import EvaluateExercise from "./Exercises/EvaluateExercise";
import AddFAQ from "./FAQ/AddFAQ";
import SearchFAQ, { SearchResultsFAQ } from "./FAQ/SearchFAQ";
import SelectFAQ from "./FAQ/SelectFAQ";
import Contactus from "./Meta/ContactUs";
import Home from "./Meta/Home";
import Footer from "./Nav/Footer";
import LeftNav from "./Nav/LeftNav";
import NavBar from "./Nav/Navbar";
import AddNote from "./Notes/AddNote";
import SearchNotes, { SearchResultNotes } from "./Notes/SearchNotes";
import Subject from "./Notes/Subject";
import ManageTrack from "./Tracks/ManageTrack";
import SearchTrack from "./Tracks/SearchTrack";
import Loading from "./UtilComponents/Loading";
import ViewCourses from "./courses/ViewCourses";
import ManageCourse from "./courses/ManageCourse";
import ManageBatches from "./batches/ManageBatches";
import SearchBatches from "./batches/SearchBatches";
import NotFound from "./UtilComponents/NotFound";
import UpdateProgress from "./batches/UpdateProgress";
import PracticeFAQ from "./FAQ/PracticeFAQ";
import Signup from "./Auth/Signup";
import Admission from "./UserManagement/Admission";
import TutorAnnotations from "./Tutorannotations";
import PythonTutorial from "./Tutor/Python";
import Tracking from "./Tracking";
import UserInfoDashboard from "./UserInfoDashboard";
import Profile from "./Auth/Profile";
import InstaQandA from "./QandA";
import Posts from "./Posts";
import LoginHistory from "./LoginHistory";
import ErrorLogs from "./ErrorLogs";
import EdatozEnquiry from "./EdatozEnquiry";
import TutorDashboard from "./Tutor/TutorModule";
import DSTutorial from "./Tutor/DataScience";
import PowerBiTutorial from "./Tutor/PowerBiTutorial";
import MachineLearningTutorial from "./Tutor/MachineLearning";
import Courses from "./Tutor/Courses";
import Createcourse from "../customcourses/Customcourses";
import Tutors from "../components/tutor";
import Vocabulary from "../components/Vocabulary/Vocabulary"
import StudyVocabulary from "./Vocabulary/StudyVocabulary";
let global_user;
window.__user = global_user;

function PortalApp(props) {
	const [themeCookie, setThemeCookie] = useCookies(["theme"]);
	if (themeCookie.theme == null) {
		setThemeCookie(
			"theme",
			window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
		);
	}
	const setTheme = (e) => {
		setThemeCookie("theme", e);
	};
	const [leftNav, setLeftNav] = useState(true);
	const [dialogue, setDialogue] = useState(0);
	const [emotion, setEmotion] = useState(0);
	const [stopControl, setStopControl] = useState(null);

	function assist(_emotion, _dialogue, _giveTime = null, callback = function () { }) {
		setEmotion(_emotion);
		setDialogue(_dialogue);
		if (_giveTime) {
			setStopControl(true);
			setTimeout(() => {
				callback();
				setStopControl(false);
			}, _giveTime);
		}
		callback();
	}
	const [user, setUser] = useState(null);
	function setGlobalUser(user) {
		global_user = user;
		setUser(user);
	}

	let [userCookie, setUserCookie, removeUserCookie] = useCookies(["user_token"]);

	useMemo(() => {
		if (userCookie.user_token)
			tryToLogin({ token: userCookie.user_token }).then((res) => {
				if (res) {
					if (res.data == "not found") console.log("token invalid");
					else setGlobalUser(res.data);
				}
			});
	}, []);

	return (
		<CookiesProvider>
			<UserContext.Provider value={user}>
				<BrowserRouter>
					{/* <Assistant emotion={emotion} dialogue={dialogue} stopControl={stopControl} /> */}

					<Container
						fluid
						data-theme={themeCookie.theme}
						className="p-0 d-flex flex-column justify-content-center w-100 h-100 "
					>
						<Row className="edz-sticky-header user-select-none">
							<Col md={12}>
								<NavBar
									theme={themeCookie.theme}
									setTheme={setTheme}
								/>
							</Col>
						</Row>
						<Row className="w-100 h-70 d-flex">
							{user == null || (
								<Col sm={"auto"} md={"auto"}>

									{leftNav && <LeftNav theme={themeCookie.theme} />}
								</Col>
							)}
							
							</Row>
							
							<Row className="w-100 h-100 d-flex mb-5">
							<Col className="h-100 w-100 align-items-center justify-content-evenly overflow-x-hidden overflow-y-auto">
								<div>
									<Routes>
									<Route path="Customcourses" element={<Createcourse />} />
									 
									<Route path="vocabulary" element={<Vocabulary />} />
									<Route path="studyvocabulary" element={<StudyVocabulary />} />
									<Route path="Tutor" element={<Tutors />} />
									<Route path="InstaQandA" element={<InstaQandA />} />
									<Route path="Posts" element={<Posts />} />
									<Route path="/posts/:id" element={<Posts />} />
									<Route path="ex">
											<Route path="do">
												<Route
													path="id/:exercise_id"
													element={<PracticeExercises />}
												/>
												<Route
													path=":course_id/"
													element={<PracticeExercises />}
												/>
												<Route
													path=":course_id/:chapter_id"
													element={<PracticeExercises />}
												/>
												<Route
													path=":course_id/:chapter_id/:topic_id"
													element={<PracticeExercises />}
												/>
											</Route>
										</Route>
										<Route
											path="loading"
											element={<Loading show="loading the site for you" />}
										/>
										<Route path="admission" element={<Admission />} />
										<Route
											path="evaluateexercise" element={<EvaluateExercise />}
										/>
										<Route
											path="tutorannotations" element={<TutorAnnotations />}
										/>
										<Route
											path="python" element={<PythonTutorial />}
										/>
										<Route
											path="datascience" element={<DSTutorial />}
										/>
										<Route
											path="powerbi" element={<PowerBiTutorial />}
										/>
										<Route
											path="machinelearning" element={<MachineLearningTutorial />}
										/>
										<Route
											path="courses" element={<Courses />}
										/>
										<Route
											path="logginghistory" element={<LoginHistory />}
										/>
										<Route
											path="errorlogging" element={<ErrorLogs />}
										/>
										<Route
											path="tracking" element={<Tracking />}
										/>
										<Route
											path="userinfodashboard" element={<UserInfoDashboard />}
										/>
										<Route
											path="edatozenquiry" element={<EdatozEnquiry />}
										/>
										<Route
											path="tutordashboard" element={<TutorDashboard />}
										/>
										<Route path="batches">
											<Route path=":mode">
												<Route path="" element={<ManageBatches />} />
												<Route
													path=":batch_id"
													element={<ManageBatches />}
												/>
											</Route>
											<Route path="progress">
												<Route path="" element={<NotFound />} />
												<Route
													path=":batch_id"
													element={<UpdateProgress />}
												/>
											</Route>
											<Route path="search">
												<Route path="" element={<SearchBatches />} />
											</Route>
										</Route>
										<Route path="courses">
											<Route path=":mode">
												<Route path="" element={<ManageCourse />} />

												<Route
													path=":course_id"
													element={<ManageCourse />}
												/>
											</Route>
											<Route path="search">
												<Route path="" element={<ViewCourses />} />
											</Route>
										</Route>
										<Route path="tracks">
											<Route path=":mode">
												<Route path="" element={<ManageTrack />} />
												<Route path=":track_id" element={<ManageTrack />} />
											</Route>
											<Route path="search">
												<Route path="" element={<SearchTrack />} />
											</Route>
										</Route>

										<Route path="notes">
											<Route path=":mode">
												<Route path="" element={<AddNote />} />
												<Route
													path=":course_id/:chapter_id/:topic_id"
													element={<AddNote />}
												/>
											</Route>
											<Route path="search">
												<Route path="" element={<SearchNotes />} />
												<Route
													path=":course_id"
													element={<SearchResultNotes />}
												/>
												<Route
													path=":course_id/:chapter_id"
													element={<SearchResultNotes />}
												/>
												<Route
													path=":course_id/:chapter_id/:topic_id"
													element={<SearchResultNotes />}
												/>
											</Route>
											<Route path="sub">
												<Route path=":course_id" element={<Subject />} />
												<Route
													path=":course_id/:chapter_id"
													element={<Subject />}
												/>
												<Route
													path=":course_id/:chapter_id/:topic_id"
													element={<Subject />}
												/>
											</Route>
										</Route>
										<Route path="ex">
											<Route path="" element={<SelectExercises />} />
											<Route path=":mode">
												<Route path="" element={<AddNewexercise />} />
												<Route
													path=":exercise_id"
													element={<AddNewexercise />}
												/>
											</Route>
											<Route path="search">
												<Route path="" element={<SearchExercises />} />

												<Route
													path=":course_id/"
													element={<SearchResultsExercises />}
												/>
												<Route
													path=":course_id/:chapter_id"
													element={<SearchResultsExercises />}
												/>
												<Route
													path=":course_id/:chapter_id/:topic_id"
													element={<SearchResultsExercises />}
												/>
												<Route
													path=":course_id/:chapter_id/:topic_id/:complexity_id"
													element={<SearchResultsExercises />}
												/>
											</Route>
										</Route>

										<Route path="faq">
											<Route path="" element={<SelectFAQ />} />
											<Route path=":mode">
												<Route path="" element={<AddFAQ />} />
												<Route path=":faq_id" element={<AddFAQ />} />
											</Route>
											<Route path="do">
												<Route
													path="id/:faq_id"
													element={<PracticeFAQ />}
												/>
												<Route
													path=":course_id/"
													element={<PracticeFAQ />}
												/>
												<Route
													path=":course_id/:chapter_id"
													element={<PracticeFAQ />}
												/>
												<Route
													path=":course_id/:chapter_id/:topic_id"
													element={<PracticeFAQ />}
												/>
											</Route>
											<Route path="search">
												<Route path="" element={<SearchFAQ />} />
												<Route
													path=":course_id/"
													element={<SearchResultsFAQ />}
												/>
												<Route
													path=":course_id/:chapter_id"
													element={<SearchResultsFAQ />}
												/>
												<Route
													path=":course_id/:chapter_id/:topic_id"
													element={<SearchResultsFAQ />}
												/>
												<Route
													path=":course_id/:chapter_id/:topic_id/:complexity_id"
													element={<SearchResultsFAQ />}
												/>
											</Route>
										</Route>
										<Route
											path="/"
											element={
												<Login
													user={user}
													setUser={setGlobalUser}
													setUserCookie={setUserCookie}
												/>
											}
										/>
										<Route path="home" element={<Home />} />
										<Route path="contactus" element={<Contactus />} />
										<Route
											path="login"
											element={
												<Login
													user={user}
													setUser={setGlobalUser}
													setUserCookie={setUserCookie}
												/>
											}
										/>
										<Route
											path="logout"
											element={
												<Logout
													setUser={setGlobalUser}
													removeUserCookie={removeUserCookie}
												/>
											}
										/>
										<Route
											path="dashboard"
											element={<Dashboard assist={assist} />}
										/>
										<Route
											path="signup" element={<Signup />}
										/>
										{/* <Route 
										path="profile"
										element={<Profile/>}
										/> */}
									</Routes>
								</div>
							</Col>
						</Row>
						<Row className="user-select-none">
							<Col md={12}>
								<Footer />
							</Col>
						</Row>
					</Container>
				</BrowserRouter>
			</UserContext.Provider>
		</CookiesProvider>
	);
}
export { global_user };
export default PortalApp;