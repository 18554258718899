import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Tutor/Calender.css';

const TutorDashboard = () => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({ date: '', title: '', description: '' });
  const [editEvent, setEditEvent] = useState(null); // State for the event being edited

  // Fetch events from the backend
  useEffect(() => {
    axios.get('http://localhost:5000/events')
      .then(response => setEvents(response.data))
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  const handleAddEvent = () => {
    axios.post('http://localhost:5000/events', newEvent)
      .then(response => {
        setEvents([...events, response.data.event]);
        setNewEvent({ date: '', title: '', description: '' });
      })
      .catch(error => console.error('Error adding event:', error));
  };

  const handleEditEvent = (event) => {
    setEditEvent(event);
    setNewEvent(event); // Populate the form with event data
  };

  const handleUpdateEvent = () => {
    axios.put(`http://localhost:5000/events/${editEvent._id}`, editEvent)
      .then(response => {
        setEvents(events.map(event => event._id === response.data.event._id ? response.data.event : event));
        setEditEvent(null);
        setNewEvent({ date: '', title: '', description: '' });
      })
      .catch(error => console.error('Error updating event:', error));
  };

  // Function to render the calendar for October 2024
  const renderCalendar = () => {
    const daysInMonth = 31;
    const firstDayOfMonth = new Date(2024, 9, 1).getDay(); // October 2024 starts on a Tuesday (day 2)
    
    const rows = [];
    let cells = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      cells.push(<td key={`empty-${i}`} className="empty-cell"></td>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = `2024-10-${day.toString().padStart(2, '0')}`;

      const dayEvents = events.filter(event => event.date === currentDate);
      cells.push(
        <td key={day}>
          <div className="calendar-cell">
            <strong>{day}</strong>
            {dayEvents.length > 0 && (
              <div className="event-list">
                {dayEvents.map(event => (
                  <div key={event._id}>
                    <strong>{event.title}</strong>
                    <p>{event.description}</p>
                    <button onClick={() => handleEditEvent(event)} className="edit-button">Edit</button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </td>
      );

      if ((day + firstDayOfMonth) % 7 === 0 || day === daysInMonth) {
        rows.push(<tr key={`week-${day}`}>{cells}</tr>);
        cells = [];
      }
    }

    return rows;
  };

  return (
    <div className="calendar">
      <h2>October 2024</h2>

      {/* Event Form */}
      <div className="event-form">
        <input
          type="date"
          value={newEvent.date}
          onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
        />
        <input
          type="text"
          placeholder="Event Title"
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
        />
        <input
          type="text"
          placeholder="Event Description"
          value={newEvent.description}
          onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
        />
        {editEvent ? (
          <button onClick={handleUpdateEvent}>Update Event</button>
        ) : (
          <button onClick={handleAddEvent}>Add Event</button>
        )}
      </div>

      {/* Calendar Table */}
      <table className="calendar-table">
        <thead>
          <tr>
            <th>Sunday</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
          </tr>
        </thead>
        <tbody>
          {renderCalendar()}
        </tbody>
      </table>
    </div>
  );
};

export default TutorDashboard;
