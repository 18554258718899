import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import FileHandling from '../../api/pdf/FileHandling.pdf';
import OOPs from '../../api/pdf/OOPs.pdf';
import DS_INTRO from '../../api/pdf/DS_INTRO.pdf';
import Power_Bi from '../../api/pdf/Power_Bi.pdf';


const PdfViewer = () => {
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);

    // Courses and corresponding chapters
    const courses = {
        course1: {
            chapters: {
                chapter1: FileHandling,
                chapter2: OOPs,
            },
        },
        course2: {
            chapters: {
                chapter1: DS_INTRO,
                chapter2: Power_Bi,
            },
        },
    };

    // Handle course selection
    const handleCourseChange = (e) => {
        const course = e.target.value;
        setSelectedCourse(course);
        setSelectedChapter(null); // Reset chapter selection when course changes
    };

    // Handle chapter selection
    const handleChapterChange = (e) => {
        const chapter = e.target.value;
        setSelectedChapter(courses[selectedCourse]?.chapters[chapter]);
    };

    return (
        <div>
            {/* First dropdown: Select Course */}
            <select onChange={handleCourseChange} value={selectedCourse || ''}>
                <option value="">Select Course</option>
                <option value="course1">Python</option>
                <option value="course2">DataScience</option>
            </select>

            {/* Second dropdown: Select Chapter (only show if a course is selected) */}
            {selectedCourse && (
                <select onChange={handleChapterChange} value={selectedChapter || ''}>
                    <option value="">Select Chapter</option>
                    <option value="chapter1">Chapter 1</option>
                    <option value="chapter2">Chapter 2</option>
                </select>
            )}

            {/* Display PDF if a chapter is selected */}
            {selectedChapter && (
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={selectedChapter} />
                </Worker>
            )}
        </div>
    );
};

export default PdfViewer;
